@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 100px;

    font-family: $inter;
    font-weight: 500;

    transition: all 0.3s;
    background: rgba(#000, .7);

    .headerRowLinks {
        a {
            color: #fff;
            transition: all .3s;

            &:hover {
                color: rgba(#fff, .7);
            }
        }
        .linkActive {
            color: #fff;
            font-weight: 700;
        }
    }

    &.lightBg {
        .headerRowLinks {
            a {
                color: #285B5C;
                transition: all .3s;

                &:hover {
                    color: #000f18;
                }
            }
            .linkActive {
                color: #285B5C;
            }
        }
        :global(.Dropdown-control) {
            color: rgba(255, 255, 255, 0.70) !important;
        }
        :global(.Dropdown-arrow) {
            border-color: rgba(0, 0, 0, 0.70) transparent transparent !important;
        }
        :global(.is-open .Dropdown-arrow) {
            border-color: transparent transparent rgba(0, 0, 0, 0.70) !important;
        }
    }

    &::before {
        content: "";
        background: #181818;
        position: absolute;
        width: 100%;
        height: 0;
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
    }
    //&Active {
    //    height: 100%;
    //    &::before {
    //        opacity: 1;
    //        height: 100%;
    //    }
    //    .langs {
    //        display: none;
    //    }
    //    path {
    //        fill: white;
    //    }
    //}
    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;
        .mobileContainer {
            padding: 0 40px;
            width: 100%;
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
        .aLink {
            display: block;
            margin: 20px 0;
            text-align: center;
            color: white;
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
        }
        .btn {
            justify-content: center;
            display: flex;
            margin-top: 40px;
            @media (max-width: 600px) {
                justify-content: center;
            }
            a {
                @media (min-width: 1221px) {
                    display: none;
                }
                display: block;
                padding: 8px 24px;
                background: #FF871A;
                border: 1px solid #FF871A;
                border-radius: 30px;
                font-family: $roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 150%;
                color: #181818;
                cursor: pointer;
                transition: all 0.3s;
                @media (max-width: $md1+px) {
                    font-size: 16px;
                }
                @media (max-width: $md2+px) {
                    font-size: 14px;
                }
                &:hover {
                    color: #FF8719;
                    background: transparent;
                }
            }
        }
        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }
    .headerRow {
        display: grid;
        grid-template-columns: 115px 1fr auto;
        gap: 30px;
        align-items: center;
        padding: 20px 0;
        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }

        @media (min-width: $md5+px) {
            justify-content: flex-start;
            gap: 60px;
        }
        &Logo {
            position: relative;
            top: -16px;
            @media (max-width: $md3+px) {
                width: 120px;
            }
            @media (max-width: $md6+px) {
            }
            a {
                display: block;
                height: 100%;
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        &Links {
            //@media (max-width: $md3+px) {
            //    display: none;
            //}
            position: relative;
            display: flex;

            gap: 50px;
            font-family: $inter;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            @media (max-width: $md2+px) {
                font-size: 16px;
                line-height: 21px;
                gap: 40px;
            }
            //.linkActive {
            //    position: relative;
            //    &::before {
            //        content: "";
            //        position: absolute;
            //        left: 0;
            //        bottom: -2px;
            //        width: 100%;
            //        height: 1px;
            //        background: #fff;
            //    }
            //}
        }
        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;
            .login {
                @media (max-width: $md3+px) {
                    display: none;
                }
                padding: 5px 20px;
                background: #FF8719;
                border: 1px solid #FF8719;
                font-family: $roboto;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                color: white;
                cursor: pointer;
                transition: all 0.3s;
                border-radius: 30px;
                @media (max-width: $md2+px) {
                    font-size: 14px;
                }
                &:hover {
                    color: #000f18;
                    border: 1px solid #000f18;
                    background: transparent;
                }
            }
            .burger {
                @media (min-width: 1221px) {
                    display: none;
                }
                cursor: pointer;
                display: flex;
                position: relative;
                width: 24px;
                height: 20px;
                transition: all 0.3s;
                span {
                    transition: all 0.3s;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    height: 2px;
                    background: #FF8719;
                    &:nth-child(1) {
                        top: 0;
                    }
                    &:nth-child(2) {
                        top: calc(50% - 1px);
                    }
                    &:nth-child(3) {
                        bottom: 0;
                    }
                }
                &Active {
                    span {
                        &:nth-child(1) {
                            transform: rotate(405deg) translateY(13px);
                            left: 7px;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            transform: rotate(-405deg) translateY(-13px);
                            left: 7px;
                        }
                    }
                }
            }
        }
    }
}
