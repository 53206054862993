@import "../../../styles/var";

.feedback {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 100px;
  margin-top: 100px;

  @media (min-width: $md5+px) {
    margin-top: 120px;
  }

  @media (min-width: $md2+px) {
    margin-top: 112px;
  }
}

.feedback__container {
  position: relative;

  padding: 40px;
  padding-bottom: 67px;
  border-radius: 20px;
  background: #285B5C;
  overflow: hidden;
}

.feedback__decoCircleLeft {
  position: absolute;
  top: 0;
  left: 0;

  &::before {
    @media (min-width: $md2+px) {
      content: "";
    }

    position: absolute;
    left: -262px;
    top: -71px;

    width: 382px;
    height: 382px;
    border: 1.75px dashed #376784;

    border-radius: 50%;
  }

  &::after {
    @media (min-width: $md2+px) {
      content: "";
    }

    position: absolute;
    left: -320px;
    top: -129px;

    width: 498px;
    height: 498px;
    border: 1.75px dashed #376784;

    border-radius: 50%;
  }
}

.feedback__decoCircleRight {
  position: absolute;
  right: 0;
  bottom: 0;

  &::before {
    @media (min-width: $md2+px) {
      content: "";
    }

    position: absolute;
    right: -390px;
    bottom: -423px;

    width: 588px;
    height: 588px;
    border: 1.75px dashed #376784;

    border-radius: 50%;
  }

  &::after {
    @media (min-width: $md2+px) {
      content: "";
    }

    position: absolute;
    right: -480px;
    bottom: -513px;

    width: 767px;
    height: 767px;
    border: 1.75px dashed #376784;

    border-radius: 50%;
  }
}

.feedback__title {
  padding-top: 29px;
  margin: 0;
  margin-bottom: 20px;

  color: #FFF;
  font-size: 20px;
  text-align: center;
  font-family: $lato;
  font-weight: 600;
  letter-spacing: -1.12px;

  @media (min-width: $md5+px) {
    padding-top: 45px;
    font-size: 24px;
  }

  @media (min-width: $md2+px) {
    padding-top: 40px;
    font-size: 32px;
  }
}

.feedback__subtitle {
  display: block;
  margin: 0;
  margin-bottom: 12px;

  color: #FFF;

  text-align: center;
  font-family: $inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;

  @media (min-width: $md5+px) {
    font-size: 16px;
  }

  @media (min-width: $md2+px) {
    font-size: 20px;
  }
}

.feedback__descr {
  max-width: 460px;
  margin: 0 auto;
  margin-bottom: 26px;

  color: rgba(255, 255, 255, 0.70);

  text-align: center;
  font-family: $inter;
  font-size: 14px;
  line-height: 150%;

  @media (min-width: $md5+px) {
    font-size: 16px;
  }
}

.feedback__form {
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
}

.feedback__row + .feedback__row {
  margin-top: 12px;

  @media (min-width: $md5+px) {
    margin-top: 20px;
  }
}

.feedback__row {
  display: flex;
  flex-direction: column;

  @media (min-width: $md5+px) {
    flex-direction: row;
  }
}

.feedback__input + .feedback__input {
  margin-top: 20px;

  @media (min-width: $md5+px) {
    margin-top: 0;
    margin-left: 20px;
  }
}

.feedback__input {
  width: 100%;
  //height: 47px;
  padding: 12px 20px;
  border: 1px solid rgba(255, 255, 255, 0.40);

  color: rgba(255, 255, 255, 0.50);

  font-family: $inter;
  font-size: 16px;

  border-radius: 10px;
  background: #285B5C;

  @media (min-width: $md5+px) {
    max-width: 50%;
  }
}

.feedback__checkbox {
  margin-top: 20px;

  color: rgba(255, 255, 255, 0.70);

  font-family: $inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  input {
    position: relative;
    bottom: -2px;

    margin-right: 8px;
    background: rgba(255, 255, 255, 0.20);
  }
}

.feedback__controls {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  margin: 0 auto;
  margin-top: 35px;

  @media (min-width: $md5+px) {
    padding-bottom: 45px;
  }

  @media (min-width: $md2+px) {
    padding-bottom: 65px;
  }
}
