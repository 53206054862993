@import "../../../styles/var";

.banner {
    position: relative;

    min-height: 600px;
    padding-top: 100px;
    padding-bottom: 0;

    color: white;

    overflow: hidden;

    @media (min-width: $md5+px) {
        padding-top: 140px;
    }

    @media (min-width: $md3+px) {
        min-height: auto;
        padding-top: 150px;
        padding-bottom: 120px;
        background-position: right;
    }
}

.banner__container {
    display: flex;
    flex-direction: column;

    @media (min-width: $md4+px) {
        flex-direction: row;
    }
}

.banner__content {
    position: relative;
    z-index: 2;

    @media (min-width: $md2+px) {
        width: 630px;
        margin-right: 50px;
    }
}

.banner__title {
    max-width: 670px;
    margin: 0;
    margin-bottom: 20px;

    color: #95A7F2;

    font-family: $lato;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -1.12px;

    @media (min-width: $md5+px) {
        margin-bottom: 40px;
        font-size: 48px;
        letter-spacing: -1.68px;
    }

    @media (min-width: $md2+px) {
        padding-top: 111px;
        font-size: 64px;
        letter-spacing: -2.24px;
    }
}

.banner__descr {
    max-width: 670px;
    color: rgba(255, 255, 255, 0.70);

    font-family: $inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;

    @media (min-width: $md5+px) {
        font-size: 20px;
    }
}

.banner__preview {
    flex-grow: 2;
    padding-top: 60px;

    @media (min-width: $md5+px) {
        max-width: 800px;
        margin: 0 auto;
    }

    @media (min-width: $md4+px) {
        padding-top: 0;
    }

    @media (min-width: $md2+px) {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        height: auto;
        max-width: none;
    }
}

.banner__previewImg {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
}
