@import "../../../styles/var";

.button {
  padding: 13px 30px;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  background: #95A7F2;
  //box-shadow: 0px 0px 1px 0px #FE9839, 0px 0px 3px 0px #FE9839, 0px 0px 11px 0px #FE9839, 0px 0px 22px 0px #FE9839, 0px 0px 38px 0px #FE9839, 0px 0px 66px 0px #FE9839;
  border-radius: 5px;
  font-family: $inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: black;
  transition: all 0.3s;
  user-select: none;



  &:hover {
    color: white;
    background: rgba(#95A7F2, .7);
  }

  &Transparent {
    //background: transparent;
    //color: #FF8719;

    //&:hover {
    //  background: #FF8719;
    //  color: white;
    //}
  }
}
