@import "../../../styles/var";

.banner {
    position: relative;

    padding-top: 110px;

    overflow: hidden;

    @media (min-width: $md5+px) {
        padding-top: 110px;
    }

    @media (min-width: $md3+px) {
        padding-top: 180px;
    }

    @media (min-width: $md2+px) {
        padding-top: 140px;
    }
}

.banner__container {
}

.banner__wrap {
    position: relative;
    z-index: 2;

    padding: 45px 40px;

    border-radius: 20px;
    background-color: #2B2B2B;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;

    @media (min-width: $md5+px) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        //min-height: 420px;

        text-align: right;
    }

    @media (min-width: $md2+px) {
        min-height: 377px;
    }
}

.banner__title {
    margin: 0;
    margin-bottom: 15px;

    color: #FFF;

    font-family: $lato;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.4px;

    span {
        color: #95A7F2;
    }

    @media (min-width: $md5+px) {
        font-size: 32px;
    }

    @media (min-width: $md2+px) {
        font-size: 40px;
    }
}

.banner__descr {
    color: rgba(255, 255, 255, 0.70);

    font-family: $inter;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;

    @media (min-width: $md5+px) {
        max-width: 50%;
    }
}

.banner__controls {
    display: block;
    //width: 133px;
    margin-top: 20px;

    @media (min-width: $md5+px) {
        margin-top: 25px;
    }

    @media (min-width: $md2+px) {
        margin-top: 40px;
    }
}

.banner__preview {
    position: absolute;
    left: 0;
    bottom: 0;

    display: none;

    @media (min-width: $md3+px) {
        display: block;
        max-width: 580px;
    }

    @media (min-width: $md2+px) {
        max-width: none;
    }
}

.banner__previewImg {
    width: 100%;
    height: auto;
    object-fit: contain;
}
