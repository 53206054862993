@import "./_var.scss";

.fw-d-none-i {
    display: none!important;
}

body,
html {
    background-color: #000;
    color: #fff;
    &.lock {
        overflow: hidden;
    }
}

.font-48 {
    font-family: $manrope;
    color: #FF8719;
    font-size: 48px;
    font-weight: 500;
    line-height: 1.4;
    @media (max-width: $md3+px) {
        font-size: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}
.font-40 {
    font-family: $manrope;
    color: #FF8719;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.4;
    @media (max-width: $md3+px) {
        font-size: 32px;
    }
    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}
.font-36 {
    font-family: $manrope;
    color: #FF8719;
    font-size: 36px;
    font-weight: 400;
    line-height: 1.5;
    @media (max-width: $md3+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.font-28 {
    font-family: $roboto;
    color: rgba(0, 0, 0, 0.7);
    font-size: 28px;
    font-weight: 400;
    line-height: 1.5;
    @media (max-width: $md3+px) {
        font-size: 24px;
    }
    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}
.font-24 {
    font-family: $manrope;
    color: #ffd348;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    @media (max-width: $md3+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.font-20 {
    font-family: $manrope;
    color: #FF8719;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}
.font-16 {
    font-weight: $roboto;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.7);
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.mt {
    margin-top: 160px;
    @media (max-width: $md1+px) {
        margin-top: 140px;
    }
    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
}

.pt {
    padding-top: 160px;
    @media (max-width: $md1+px) {
        padding-top: 140px;
    }
    @media (max-width: $md6+px) {
        padding-top: 120px;
    }
}

.pb {
    padding-bottom: 160px;
    @media (max-width: $md1+px) {
        padding-bottom: 140px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 120px;
    }
}

.d-none {
    display: none;
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Dropdown-control {
    font-family: $roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: white;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $roboto;
    font-style: normal;
    font-size: 14px;
}
.Dropdown-arrow {
    //border-color: white transparent transparent;
}
.is-open .Dropdown-arrow {
    //border-color: transparent transparent white;
}

.custom-scrollbar {
    -webkit-overflow-scrolling: inherit;
    transform: translate3d(0, 0, 0);
}

.custom-scrollbar.--x {
    overflow-x: auto;
    overflow-y: hidden;
}

.custom-scrollbar.--y {
    overflow-x: hidden;
    overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 2px;
    width: 1px;
}

.custom-scrollbar::-webkit-scrollbar-button:end:increment,
.custom-scrollbar::-webkit-scrollbar-button:start:decrement {
    background: 0 0;
    display: none;
}

.custom-scrollbar::-webkit-scrollbar-track-piece {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal,
.custom-scrollbar::-webkit-scrollbar-thumb:vertical {
    background: #FF8719;
    border-radius: 5px;
    display: block;
    height: 50px;
}

.decoAngleContainer {
    position: relative;
}

.decoAngleTop {
    &::before {
        content: "";

        position: absolute;
        top: 0;
        left: 0;

        display: block;
        width: 37px;
        height: 37px;
        border-top: 2px solid #FF8719;
        border-left: 2px solid #FF8719;
        border-top-left-radius: 20px;

        filter: drop-shadow(0px 0px 4px #FF830F) drop-shadow(0px 0px 8px #FF830F) drop-shadow(0px 0px 31px #FF830F) drop-shadow(0px 0px 42px #FF830F) drop-shadow(0px 0px 66px #FF830F);
    }

    &::after {
        content: "";

        position: absolute;
        top: 0;
        right: 0;

        display: block;
        width: 37px;
        height: 37px;
        border-top: 2px solid #FF8719;
        border-right: 2px solid #FF8719;
        border-top-right-radius: 20px;

        filter: drop-shadow(0px 0px 4px #FF830F) drop-shadow(0px 0px 8px #FF830F) drop-shadow(0px 0px 31px #FF830F) drop-shadow(0px 0px 42px #FF830F) drop-shadow(0px 0px 66px #FF830F);
    }
}

.decoAngleBottom {
    &::before {
        content: "";

        position: absolute;
        bottom: 0;
        left: 0;

        display: block;
        width: 37px;
        height: 37px;
        border-bottom: 2px solid #FF8719;
        border-left: 2px solid #FF8719;
        border-bottom-left-radius: 20px;

        filter: drop-shadow(0px 0px 4px #FF830F) drop-shadow(0px 0px 8px #FF830F) drop-shadow(0px 0px 31px #FF830F) drop-shadow(0px 0px 42px #FF830F) drop-shadow(0px 0px 66px #FF830F);
    }

    &::after {
        content: "";

        position: absolute;
        bottom: 0;
        right: 0;

        display: block;
        width: 37px;
        height: 37px;
        border-bottom: 2px solid #FF8719;
        border-right: 2px solid #FF8719;
        border-bottom-right-radius: 20px;

        filter: drop-shadow(0px 0px 4px #FF830F) drop-shadow(0px 0px 8px #FF830F) drop-shadow(0px 0px 31px #FF830F) drop-shadow(0px 0px 42px #FF830F) drop-shadow(0px 0px 66px #FF830F);
    }
}

.decoAngleContainer.--small {
    .decoAngleTop,
    .decoAngleBottom {
        &::before,
        &::after {
            width: 20px;
            height: 20px;
            border-width: 1px;
        }
    }

    .decoAngleTop::before {
        border-top-left-radius: 8px;
    }

    .decoAngleTop::after {
        border-top-right-radius: 8px;
    }

    .decoAngleBottom::before {
        border-bottom-left-radius: 8px;
    }

    .decoAngleBottom::after {
        border-bottom-right-radius: 8px;
    }
}

.btnAnchor {
    position: relative;
}

.slider {
    &.--vision {
        width: 340px;
        height: 425px;
        margin: 0 auto;

        @media (min-width: $md6+px) {
            width: 440px;
        }
    }

    &.--adaptive {
        @media (min-width: $md2+px) {
            width: 100%;
            height: auto;

            .slide {
                position: relative;
                z-index: 1;
                visibility: visible;
                opacity: 1;
                transform: translate(0, 0);
            }

            .slide + .slide {
                margin-left: 20px;
            }

            .btnLeft,
            .btnRight {
                display: none;
            }
        }
    }
}

.slide {
    position: absolute;
    visibility: hidden;
    z-index: -2;
    transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;

    transition: all 0.8s;
}

.slide.active {
    position: static;
    visibility: visible;
    z-index: 1;
    transform: translateY(0) translateX(0);
    opacity: 1;
}

.sliderControls {
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);

    display: block;
    width: 100px;
    height: 40px;

    @media (min-width: $md6+px) {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: none;

        width: auto;
        height: auto;
    }
}

.btnLeft,
.btnRight {
    width: 40px;
    height: 40px;
    border-radius: 20px;

    backdrop-filter: blur(11px);

    background-color: rgba(255, 255, 255, 0.06);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.btnLeft {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    //background-image: url("../assets/img/icon-arrow-left.svg");

    @media (min-width: $md6+px) {
        bottom: 150px;
        left: -60px;
        transform: translateY(20px);
    }
}

.btnRight {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;

    //background-image: url("../assets/img/icon-arrow-right.svg");

    @media (min-width: $md6+px) {
        bottom: 150px;
        right: -60px;
        transform: translateY(20px);
    }
}

.slide-in-tr {
    animation: slide-in-tr 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-tr {
    0% {
        transform: translateY(-700px) translateX(1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
}

.slide-out-tl {
    animation: slide-out-tl 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes slide-out-tl {
    0% {
        transform: translateY(0) translateX(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-1000px) translateX(-1000px);
        opacity: 0;
    }
}
