@import "../../../styles/var";

.accounts {
  position: relative;

  padding-top: 120px;
  padding-bottom: 80px;

  @media (min-width: $md5+px) {
    padding-top: 0;
    padding-bottom: 120px;
  }

  @media (min-width: $md2+px) {
    padding-top: 140px;
    padding-bottom: 160px;
  }
}

.accounts__title {
  display: block;
  margin: 0;
  margin-bottom: 40px;

  color: #9D9F9F;

  font-family: $lato;
  font-size: 32px;
  text-align: center;
  font-weight: 600;
  letter-spacing: -1.4px;

  @media (min-width: $md5+px) {
    font-size: 36px;
  }

  @media (min-width: $md2+px) {
    margin-bottom: 60px;
    font-size: 40px;
  }
}

.accounts__list {
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $md5+px) {
    justify-content: center;
  }

  @media (min-width: $md2+px) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-left: -20px;
  }
}

.accounts__item {
  position: relative;

  width: 100%;
  padding: 60px 20px 48px;
  margin-top: 40px;

  @media (min-width: $md5+px) {
    max-width: 340px;
    padding-top: 90px;
    margin-top: 100px;
  }

  @media (min-width: $md2+px) {
    max-width: 437px;
    margin-top: 60px;
    margin-left: 20px;
  }
}

.accounts__itemPreview {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 2px dashed #95A7F2;
  border-radius: 20px;

  @media (min-width: $md5+px) {
    width: 120px;
    height: 120px;
  }
}

.accounts__itemPreviewImg {
  max-width: 40px;

  @media (min-width: $md5+px) {
    max-width: 60px;
  }
}

.accounts__itemTitle {
  margin: 0;
  margin-bottom: 24px;

  color: #FF8719;

  font-family: $manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;

  @media (min-width: $md5+px) {
    margin-bottom: 20px;
    font-size: 24px;
  }

  @media (min-width: $md2+px) {
    margin-bottom: 12px;
    font-size: 32px;
  }
}

.accounts__itemDescr {
  color: #9D9F9F;

  text-align: center;
  font-family: $inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
