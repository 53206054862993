@import "../../../styles/var";

.terms {
  position: relative;

  @media (min-width: $md5+px) {
    //padding-top: 180px;
    padding-bottom: 160px;
  }

  @media (min-width: $md2+px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.terms__container {
  position: relative;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: $md3+px) {
    flex-direction: row;
  }
}

.terms__content {
  padding: 40px 14px 75px;

  border-radius: 20px;
  background: #2B2B2B;

  @media (min-width: $md5+px) {
    width: 100%;
    padding: 60px 20px 378px;
  }

  @media (min-width: $md3+px) {
    padding: 80px;
  }
}

.terms__title {
  margin: 0;
  margin-bottom: 40px;

  color: #fff;

  font-family: $lato;
  font-size: 32px;
  font-weight: 600;
  text-align: center;

  span {
    color: #95A7F2;
  }

  @media (min-width: $md5+px) {
    font-size: 36px;
  }

  @media (min-width: $md2+px) {
    text-align: left;
    font-size: 40px;
  }
}

.terms__list {
  max-width: 600px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.terms__item + .terms__item {
  margin-top: 26px;
}

.terms__item {
}

.terms__itemTitle {
  margin: 0;
  margin-bottom: 6px;
  color: #fff;

  font-family: $inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;

  &::before {
    content: "";

    position: relative;
    bottom: -3px;

    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 22px;

    background-image: url("../../../assets/img/icon-point.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.terms__itemDescr {
  padding-left: 42px;

  color: rgba(255, 255, 255, 0.70);

  font-family: $inter;
  font-size: 16px;
  line-height: 150%;
}

.terms__avatars {
  display: none;

  position: absolute;
  right: 20px;
  left: 20px;
  bottom: 0;
  top: 0;

  overflow: hidden;

  @media (min-width: $md5+px) {
    display: block;

    right: 40px;
    left: 40px;
  }

  @media (min-width: $md3+px) {

    left: auto;
    right: 0;
    width: 600px;
  }

  &::before {
    content: "";

    position: absolute;
    left: 50%;
    bottom: -220px;
    transform: translateX(-50%);

    width: 222px;
    height: 222px;
    border: 1.75px dashed #95A7F2;

    border-radius: 50%;

    @media (min-width: $md5+px) {
      left: 50%;
      bottom: -220px;
      transform: translateX(-50%);

      width: 417px;
      height: 417px;
    }

    @media (min-width: $md3+px) {
      left: auto;
      right: -215px;
      bottom: -112px;
      transform: none;

      width: 588px;
      height: 588px;
    }
  }

  &::after {
    content: "";

    position: absolute;
    left: 50%;
    right: -305px;
    bottom: -284px;
    z-index: 0;
    transform: translateX(-50%);

    width: 289px;
    height: 289px;

    border: 1.75px dashed #95A7F2;
    border-radius: 50%;

    @media (min-width: $md5+px) {
      left: 50%;
      bottom: -284px;
      transform: translateX(-50%);

      width: 545px;
      height: 545px;
    }

    @media (min-width: $md3+px) {
      left: auto;
      right: -305px;
      bottom: -202px;
      transform: none;

      width: 767px;
      height: 767px;
    }
  }
}

.terms__avatarsItem {
  position: absolute;
  z-index: 2;

  color: #000;
  border-radius: 50%;

  &:nth-child(1) {
    @media (min-width: $md5+px) {
      left: 210px;
      bottom: 219px;

      width: 128px;
      height: 128px;
    }

    @media (min-width: $md3+px) {
      left: auto;
      bottom: auto;
      top: 76px;
      right: 148px;

      width: 178px;
      height: 178px;
    }

    .terms__avatarsCheckbox {
      width: 49px;
      height: 49px;

      @media (min-width: $md5+px) {
        width: 34px;
        height: 34px;
      }

      @media (min-width: $md3+px) {
        width: 49px;
        height: 49px;
      }
    }

    .terms__avatarsIncome {
      bottom: 10px;
      right: -58px;

      @media (min-width: $md5+px) {
        width: 108px;
        height: 43px;
        font-size: 17px;
      }

      @media (min-width: $md3+px) {
        width: 152px;
        height: 61px;
        font-size: 24px;
      }
    }
  }

  &:nth-child(2) {
    bottom: 6px;
    left: 12px;

    width: 93px;
    height: 93px;

    @media (min-width: $md5+px) {
      left: 41px;
      bottom: 12px;

      width: 176px;
      height: 176px;
    }

    @media (min-width: $md3+px) {
      top: 347px;
      right: 316px;
      left: auto;
      bottom: auto;

      width: 205px;
      height: 205px;
    }

    .terms__avatarsCheckbox {
      width: 78px;
      height: 78px;
      left: -17px;

      @media (min-width: $md5+px) {
        width: 55px;
        height: 55px;
      }

      @media (min-width: $md3+px) {
        width: 78px;
        height: 78px;
      }
    }

    .terms__avatarsIncome {
      top: -11px;
      right: -105px;
      background-size: 36px;

      flex-direction: column;
      padding-top: 30px;
      padding-bottom: 30px;

      color: black;
      background: #95A7F2;

      &::before {
        content: "Profit";

        display: block;
        margin-bottom: 4px;

        text-align: center;
        color: black;
        font-size: 16px;
      }

      @media (min-width: $md5+px) {
        top: -8px;
        right: -74px;

        width: 108px;
        height: 81px;
        font-size: 17px;
      }

      @media (min-width: $md3+px) {
        top: -11px;
        right: -105px;

        width: 152px;
        height: 114px;
        font-size: 24px;
      }
    }
  }

  &:nth-child(3) {
    right: 27px;
    bottom: 48px;

    width: 77px;
    height: 77px;

    @media (min-width: $md5+px) {
      right: 68px;
      bottom: 91px;

      width: 145px;
      height: 145px;
    }

    @media (min-width: $md3+px) {
      right: -21px;
      bottom: 68px;

      width: 205px;
      height: 205px;
    }

    .terms__avatarsCheckbox {
      left: -3px;
      width: 24px;
      height: 24px;

      @media (min-width: $md5+px) {
        left: -5px;
        width: 43px;
        height: 43px;
      }

      @media (min-width: $md3+px) {
        left: -17px;
        width: 61px;
        height: 61px;
      }
    }

    .terms__avatarsIncome {
      left: -37px;
      bottom: 8px;
      width: 57px;
      height: 23px;
      font-size: 9px;

      @media (min-width: $md5+px) {
        left: -70px;
        bottom: 16px;
        width: 108px;
        height: 34px;
        font-size: 17px;
      }

      @media (min-width: $md3+px) {
        left: -99px;
        bottom: 23px;
        width: 152px;
        height: 62px;
        font-size: 24px;
      }
    }
  }
}

.terms__avatarsImg {
  width: 100%;
  object-fit: contain;
}

.terms__avatarsCheckbox {
  position: absolute;
  top: 0;
  left: -7px;

  border: 1.75px dashed white;

  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.30);;
  background-image: url("../../../assets/img/icon-check.svg");
  background-size: 66%;
  background-repeat: no-repeat;
  background-position: center;
}

.terms__avatarsIncome {
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 30px;

  color: inherit;
  background: white;

  font-family: $manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
  white-space: nowrap;

  border-radius: 16px;
}
