@import "../../../styles/var";

.extra {
    position: relative;

    padding-top: 85px;
    //padding-bottom: 410px;
    //margin-top: 500px;

    @media (min-width: $md5+px) {
        padding-top: 120px;
    }
}

//.extra__hero {
//    padding-bottom: 80px;
//
//    @media (min-width: $md5+px) {
//        padding-top: 60px;
//    }
//}

.vacancy {
    position: relative;
    min-height: 639px;
    padding-top: 160px;

    @media (min-width: $md2+px) {
        padding-bottom: 60px;
    }
}

.extra__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $md4+px) {
        flex-direction: row;
        padding-bottom: 50px;
    }
}

.extra__content {
    max-width: 505px;

    @media (min-width: $md3+px) {
        max-width: 685px;
    }
}


.extra__contentMoreSpace {
    @media (min-width: $md2+px) {
        padding-top: 90px;
        padding-bottom: 100px;
    }
}

.extra__title {
    margin: 0;
    margin-bottom: 20px;

    color: #fff;

    font-family: $lato;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1.4px;

    @media (min-width: $md5+px) {
        font-size: 32px;
    }

    @media (min-width: $md2+px) {
        font-size: 40px;
    }
}

.extra__titleSlim {
    font-size: 20px;

    @media (min-width: $md5+px) {
        font-size: 24px;
    }

    @media (min-width: $md2+px) {
        font-size: 32px;
    }
}

.extra__descr {
    margin: 0;
    margin-bottom: 20px;

    color: rgba(255, 255, 255, 0.70);

    font-family: $inter;
    font-size: 14px;
    line-height: 150%;

    @media (min-width: $md5+px) {
        margin-bottom: 14px;
        font-size: 16px;
    }

    @media (min-width: $md2+px) {
        margin-bottom: 34px;
    }
}

.extra__descrMoreSpace {
    margin-bottom: 40px;

    @media (min-width: $md5+px) {
        margin-bottom: 60px;
    }

    @media (min-width: $md2+px) {
        margin-bottom: 40px;
    }
}

.extra__controls {
    margin-bottom: 60px;
}


.features {
    @media (min-width: $md5+px) {
        padding-bottom: 60px;
    }
}

.extra__featuresContainer {
    display: flex;
    flex-direction: column;

    //padding-top: 80px;

    @media (min-width: $md3+px) {
        flex-direction: row;
    }
}

.extra__featuresTitle {
    margin: 0;
    margin-bottom: 20px;

    color: #fff;

    font-family: $inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;

    @media (min-width: $md5+px) {
        font-size: 20px;
    }
}

.extra__features {
    width: 100%;
    padding: 30px 20px 35px;
    border: 2px dashed #FFF;

    border-radius: 20px;
    background: #2B2B2B;

    @media (min-width: $md5+px) {
        padding: 40px;
    }

    @media (min-width: $md2+px) {
        max-width: 666px;
    }
}

.extra__features + .extra__features {
    margin-top: 20px;

    @media (min-width: $md3+px) {
        margin-left: 22px;
        margin-top: 0;
    }
}

.extra__list {

}

.extra__item {
    position: relative;

    padding-left: 32px;
    margin: 0;
    margin-bottom: 12px;
    color: rgba(255, 255, 255, 0.70);

    font-family: $inter;
    font-size: 14px;
    line-height: 150%;

    &::before {
        content: "";

        position: absolute;
        left: 0;

        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 22px;

        background-image: url("../../../assets/img/icon-point.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }

    @media (min-width: $md5+px) {
        padding-left: 42px;
        font-size: 16px;
    }
}

.extra__itemTip {
    padding: 20px;
    margin-top: 20px;

    color: #F1F1F1;
    font-size: 14px;
    border-radius: 20px;
    background: black;

    &::before {
        display: none;
    }
}

.extra__preview {
    padding-top: 60px;
    padding-bottom: 120px;

    @media (min-width: $md5+px) {
        padding-bottom: 140px;
    }

    @media (min-width: $md3+px) {
        padding-top: 0;
    }

    @media (min-width: $md2+px) {
        padding-bottom: 0;
    }
}

.vacancy__second {
    padding-bottom: 120px;

    @media (min-width: $md5+px) {
        padding-bottom: 140px;
    }

    @media (min-width: $md4+px) {
        padding-bottom: 0;
    }
}

.extra__previewAbsolute {
    padding: 0;
    padding-top: 60px;

    @media (min-width: $md4+px) {
        position: absolute;
        right: 0px;
        top: 70px;
    }

    @media (min-width: $md3+px) {
        right: 120px;
        top: 170px;

        padding-top: 0;
    }

    @media (min-width: $md2+px) {
        right: 220px;
    }

    @media (min-width: $md1+px) {
        right: 370px;
    }
}

.extra__previewImg {
    display: block;
    width: 335px;
    height: 297px;
    margin: 0 auto;
    object-fit: contain;

    @media (min-width: $md5+px) {
        width: 348px;
        height: 308px;
        margin: 20px auto 0;
    }

    @media (min-width: $md5+px) {
        width: 448px;
        height: 408px;
        margin: 0 auto;
    }

    @media (min-width: $md3+px) {
        width: 548px;
        height: 508px;
        margin: 0 auto;
    }
}

.extra__previewImgSecond {
    @media (min-width: $md3+px) {
        width: 414px;
        height: 481px;
    }
}
